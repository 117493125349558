import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col, Icon } from "antd";
import classnames from "classnames";
import { REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";
import { parseDateTime } from "../../../utils/dateTimeUtils";
import { isRegistrationClosed, isRegistrationNotOpenYet } from "../../../utils/registrationUtil";
import DateTimeValueWithTimezone from "../../DateTimeValueWithTimezone";
import MyRegistrationItemAction from "./MyRegistrationItemAction";
import MyRegistrationItemStatus from "./MyRegistrationItemStatus";
import { REGISTRATION_LIST_MODE } from "./MyRegistrationsConstants";
import "./MyRegistrationItem.scss";

function MyRegistrationItem({ regSubmission, listMode }) {
  const regIsClosed = isRegistrationClosed(regSubmission.Reg.openAt, regSubmission.Reg.closeAt);
  const regIsNotOpenYet = isRegistrationNotOpenYet(regSubmission.Reg.openAt, regSubmission.Reg.closeAt);

  const containerClassName = classnames("my-registration-item__container", {
    "my-registration-item__container--disabled": regIsClosed,
  });

  return (
    <Row type="flex" justify="space-between" className={containerClassName}>
      <Col xs={24} md={14}>
        <Typography.Title level={4}>{regSubmission.Reg.name}</Typography.Title>
        <Row type="flex">
          <Col>
            <Icon type="calendar" />
          </Col>
          <Col>
            <Row type="flex">
              <div className="my-registration-item__label">Open:</div>
              <DateTimeValueWithTimezone
                value={parseDateTime(regSubmission.Reg.openAt)}
                referenceTimezoneName={regSubmission.Reg.timezoneName}
              />
            </Row>
            <Row type="flex">
              <div className="my-registration-item__label">Close:</div>
              <DateTimeValueWithTimezone
                value={parseDateTime(regSubmission.Reg.closeAt)}
                referenceTimezoneName={regSubmission.Reg.timezoneName}
              />
            </Row>
          </Col>
        </Row>
        <div className="my-registration-item__button-container">
          <MyRegistrationItemAction regIsClosed={regIsClosed} regId={regSubmission.Reg.id} listMode={listMode} />
        </div>
      </Col>
      <Col xs={24} md={10} className="item-spacing">
        <MyRegistrationItemStatus
          regSubmission={regSubmission}
          regIsClosed={regIsClosed}
          regIsNotOpenYet={regIsNotOpenYet}
          listMode={listMode}
        />
      </Col>
    </Row>
  );
}

MyRegistrationItem.propTypes = {
  regSubmission: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(REG_SUBMISSION_STATUSES)).isRequired,
    lastSubmittedAt: PropTypes.string,
    percentComplete: PropTypes.number,
    Reg: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      openAt: PropTypes.string.isRequired,
      closeAt: PropTypes.string.isRequired,
      timezoneName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

export default MyRegistrationItem;
