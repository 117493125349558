import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "antd";
import { connect } from "formik";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { REG_STEP_STATUSES } from "../../../constants/regStatusConstants";
import { STEP_TYPE } from "../../../constants/stepsConstants";
import { SCREEN_WIDTH_THRESHOLD } from "../../../constants/styleConstants";
import { getSubmitDisabled, getSubmitDisabledDraft } from "../../../utils/formUtils";
import useContainerDimensions from "../../../utils/useContainerDimensions";

const FormBottomComponent = ({
  step,
  stepIndex,
  nextStep,
  prevStep,
  handleSubmit,
  handleSubmitDraft,
  disabled,
  disableSubmit,
  disableSubmitDraft,
  saveDraftText,
  isSubmitting,
  formik,
}) => {
  const isFormik = useMemo(() => !isEmpty(formik), [formik]);

  const { width } = useContainerDimensions();

  const isSubmittingFormik = useMemo(() => {
    if (!isFormik) {
      return false;
    }

    return formik.isSubmitting;
  }, [isFormik, formik.isSubmitting]);

  const disableSubmitFromFormValidity = useMemo(() => {
    if (!isFormik) {
      return false;
    }

    const isFormValid = formik.isValid && isEmpty(formik.errors);

    return !isFormValid;
  }, [isFormik, formik.isValid, formik.errors]);

  const isSubmitDisabled = getSubmitDisabled(
    disabled,
    disableSubmit,
    true,
    disableSubmitFromFormValidity,
    false,
    handleSubmitDraft,
    false,
  );

  const isSubmitDisabledDraft = getSubmitDisabledDraft(
    disabled,
    disableSubmitDraft,
    true,
    disableSubmitFromFormValidity,
    false,
    false,
  );

  const stepType = useMemo(() => get(step, "meta.type", ""), [step]);
  const isLegalStep = useMemo(() => stepType === STEP_TYPE.LEGAL, [stepType]);
  const isStepCompleted = useMemo(() => get(step, "RegStatus.status", "") === REG_STEP_STATUSES.completed, [step]);

  function handleClickNext() {
    if (isFormik) {
      const isFormValid = formik.isValid && isEmpty(formik.errors);

      if (!isFormValid || (isFormValid && formik.dirty) || (isFormValid && !isStepCompleted)) {
        handleSubmit();
      } else {
        nextStep();
      }
    } else if (!isStepCompleted) {
      handleSubmit();
    } else {
      nextStep();
    }
  }

  /**
   * Note that previously this function and `nextButtonBuilder` had logic to show different content depending on whether
   * the current step was the last step. This has been removed, because it's not possible to actually guarantee whether
   * the current step is the last step, since it's possible that there actually are other steps after the current step
   * but those other steps are not currently visible (due to their conditions), and that after submitting the current
   * step, this will cause the re-evaluation of the conditions for the subsequent steps, and then further steps will
   * become visible which appear after the current step (becoming visible immediately after submitting the current
   * step). Since this is the case, the only thing we can do is assume that there may always be another step after the
   * current step, and leave it up to the `nextStep` function in PersonDetailRegistrations to deal with the logic of
   * going to the next step (if there ends up being a next step after submitting the current step), or not.
   */
  function nextButtonText() {
    if (isLegalStep) {
      return "Agree and Continue";
    }

    if (isFormik) {
      const isFormValid = formik.isValid && isEmpty(formik.errors);

      if (formik.isSubmitting || formik.dirty || !isFormValid) {
        return "Save and Continue";
      }
    }

    return "Continue";
  }

  const isPreviousButtonVisible = stepIndex > 0;

  const buttonPadding = useMemo(()=>{
    if (width < SCREEN_WIDTH_THRESHOLD.MEDIUM){
      return "0px 5px";
    }

    return "0px 15px";
  },[width]);

  return (
    <div className="person-registrations-bottom">
      {isPreviousButtonVisible && (
        <Button onClick={prevStep} disabled={isSubmitting || isSubmittingFormik} style={{padding: buttonPadding}}>
          <Icon type="left" />
          Previous
        </Button>
      )}

      <div style={{ marginLeft: "auto" }}>
        {!!handleSubmitDraft && (
          <Button
            type="primary"
            loading={isSubmitting || isSubmittingFormik}
            disabled={isSubmitDisabledDraft}
            onClick={handleSubmitDraft}
            style={{ marginRight: 10, padding: buttonPadding }}
          >
            {saveDraftText}
          </Button>
        )}

        <Button
          type="primary"
          loading={isSubmitting || isSubmittingFormik}
          disabled={isSubmitDisabled}
          onClick={handleClickNext}
          style={{padding: buttonPadding}}
        >
          {nextButtonText()}
          <Icon type="right" />
        </Button>
      </div>
    </div>
  );
};

FormBottomComponent.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitDraft: PropTypes.func,
  disabled: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  disableSubmitDraft: PropTypes.bool,
  saveDraftText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSubmitting: PropTypes.bool,
  formik: PropTypes.object,
};

FormBottomComponent.defaultProps = {
  handleSubmitDraft: null,
  disabled: false,
  disableSubmit: false,
  disableSubmitDraft: false,
  saveDraftText: "Save Draft",
  isSubmitting: false,
  formik: {},
};

// Export a version of this component wrapped with Formik's `connect` function. This can be used when appropriate (i.e.
// when FormBottomComponent is used as part of a Formik form).
export const ConnectedFormBottomComponent = connect(FormBottomComponent);

// By default, export a version of this component that is *not* wrapped with Formik's `connect` function. This can be
// used when FormBottomComponent is used in a non-Formik context, e.g. in the RegistrationStepInfo component.
export default FormBottomComponent;
