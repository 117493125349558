import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { Alert, Drawer } from 'antd';
import { get, isNil } from 'lodash';
import { SCREEN_WIDTH_THRESHOLD } from '../../../constants/styleConstants';
import useContainerDimensions from '../../../utils/useContainerDimensions';
import { useAuth0 } from '../../Auth0';
import LayoutPageHeader from '../../LayoutPageHeader';
import SpinPageContent from '../../SpinPageContent';
import { REGISTRATION_LIST_MODE } from './MyRegistrationsConstants';
import MyRegistrationsList from './MyRegistrationsList';
import MyRegistrationsNavigation from './MyRegistrationsNavigation';
// eslint-disable-next-line import/no-cycle
import { GET_PERSON, NAVIGATION_ITEMS } from './MyRegistrationsPage';

const MyRegistrationsContainerCurrentList = () => {

  const { user } = useAuth0();
  const auth0Id = get(user, "sub", null);

  const [drawerIsOpen, setDrawerIsOpen] = useState(true); 
  const { width } = useContainerDimensions();

  const { loading: personLoading, error: personError, data: personData } = useQuery(GET_PERSON, {
    variables: { auth0Id },
    skip: !user,
  });

  const parsonName = get(personData, "User.0.Person.firstName", "");
  const PersonId = get(personData, "User.0.Person.id", null);

  const navigationItems = useMemo(() => Object.values(NAVIGATION_ITEMS), []);

  if (personLoading && !personData) {
    return <SpinPageContent style={{ height: "100%" }} />;
  }

  if (personError || !personData || isNil(PersonId)) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Person failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <div className="my-registrations-page__header-container">
        <div className="my-registrations-page__header">
          <LayoutPageHeader title={`Welcome, ${parsonName}`} className="my-registrations-page__page-header" />
        </div>
      </div>
      <div className="my-registrations-page__container">
        { width < SCREEN_WIDTH_THRESHOLD.MEDIUM &&
          <Drawer bodyStyle={{padding: "0px"}} width={300} placement="left" visible={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
            <div className="my-registrations-page__aside">
              <MyRegistrationsNavigation navigationItems={navigationItems} />
            </div>
          </Drawer>
        }
        { width >= SCREEN_WIDTH_THRESHOLD.MEDIUM &&
          <div className="my-registrations-page__aside">
            <MyRegistrationsNavigation navigationItems={navigationItems} />
          </div>
        }
        <div className="my-registrations-page__content">
          <MyRegistrationsList
            headerTitle={NAVIGATION_ITEMS.CURRENT_REGISTRATIONS.title}
            PersonId={PersonId}
            listMode={REGISTRATION_LIST_MODE.CURRENT}
            drawerIsOpen={drawerIsOpen}
            setDrawerIsOpen={setDrawerIsOpen}
          />
        </div>
      </div>
    </>
  );
}

export default MyRegistrationsContainerCurrentList
