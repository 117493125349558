import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import { isNil } from "lodash";
import get from "lodash/get";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldRadio from "./FormFieldRadio";
import FormFieldSelect from "./FormFieldSelect";
import FormFieldTransfer from "./FormFieldTransfer";

const GET_COMPETITION_LIST = gql`
  query CompetitionList {
    Competition(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
  radio: "radio",
  transfer: "transfer",
};

function FormFieldCompetition(props) {
  const { meta, disabled, query, queryOptions, selectFieldOptions } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(
    query,
    queryOptions,
  );

  const options = useMemo(() => {
    const competitions = get(competitionsData, "Competition", []);

    if (!isNil(selectFieldOptions)) {
      return selectFieldOptions(competitions);
    }

    return competitions.map(competition => ({
      value: competition.id,
      label: competition.name,
    }));
  }, [competitionsData, selectFieldOptions]);

  if (competitionsLoading && !competitionsData) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (competitionsError) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Competitions failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return (
        <FormFieldCheckboxGroup {...props} disabled={disabled || competitionsLoading} meta={{ ...meta, options }} />
      );
    }
    case DISPLAY_MODES.select: {
      return (
        <FormFieldSelect
          {...props}
          disabled={disabled || competitionsLoading}
          loading={competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    case DISPLAY_MODES.radio: {
      return <FormFieldRadio {...props} disabled={disabled || competitionsLoading} meta={{ ...meta, options }} />;
    }
    case DISPLAY_MODES.transfer: {
      return <FormFieldTransfer {...props} disabled={disabled || competitionsLoading} meta={{ ...meta, options }} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldCompetition.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
  selectFieldOptions: PropTypes.func,
};

FormFieldCompetition.defaultProps = {
  disabled: false,
  query: GET_COMPETITION_LIST,
  queryOptions: {},
  selectFieldOptions: null,
};

export default FormFieldCompetition;
