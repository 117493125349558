import React from "react";
import PropTypes from "prop-types";
import { getAllTimezones } from "countries-and-timezones";
import filter from "lodash/filter";
import get from "lodash/get";
import isNil from "lodash/isNil";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { UTC_TIME_ZONE } from "../../constants/dateTimeConstants";
import FormFieldSelect from "./FormFieldSelect";

/**
 * `ct.getAllTimezone` contains deprecated time zones. A valid timezone is when its `aliasOf` is `null` and its
 * `country` is not `null`. We have to filter according to this criteria, and build our time zone options based on
 * this.
 *
 * TODO: Confirm that this current logic is correct, and that we are not accidentally filtering out some valid options.
 */
function getTimeZoneOptions() {
  let allTimezones = getAllTimezones();

  allTimezones = filter(allTimezones, tz => isNil(tz.aliasOf) && !isNil(tz.countries) && tz.countries.length > 0);

  allTimezones = map(allTimezones, tz => {
    return { label: tz.name, value: tz.name };
  });

  allTimezones.push({ label: "UTC (GMT)", value: UTC_TIME_ZONE });

  return sortBy(allTimezones, "label");
}

const TIME_ZONE_OPTIONS = getTimeZoneOptions();

function FormFieldTimezone(props) {
  const { meta } = props;

  // Use a sensible default width that fits all time zone options.
  const style = get(meta, "style", { width: 300 });

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a time zone";
  }

  return <FormFieldSelect {...props} meta={{ ...meta, options: TIME_ZONE_OPTIONS, placeholder, style }} />;
}

FormFieldTimezone.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldTimezone;
