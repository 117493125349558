import { useEffect, useState } from "react";

const useContainerDimensions = () => {
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })
  
    useEffect(() => {
      const getDimensions = () => ({
        width: window.innerWidth,
        height: window.innerHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [])
  
    return dimensions;
  };

  export default useContainerDimensions;