import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import classnames from "classnames";
import { connect } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import get from "lodash/get";
import omit from "lodash/omit";
import startsWith from "lodash/startsWith";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import "./FormFieldPhone.scss";
import "../../styles/components/react-phone-input-2.scss";

function FormFieldPhone(props) {
  const {
    name,
    meta,
    disabled,
    formik: { values, setFieldValue, setFieldTouched },
    readOnly,
  } = props;

  const fieldValue = get(values, name, null);

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const inputClassNames = classnames("ant-input", { "ant-input-disabled": disabled });

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter a phone";
  }

  const handleClickClear = () => {
    setFieldValue(name, null, true);
    setFieldTouched(name, true);
  };

  const handleChangePhone = phoneInputValue => {
    let newFieldValue = phoneInputValue;

    // The value we get from the PhoneInput `onChange` function does not include the leading "+" character, even though
    // that is shown in the UI. We add it here so that our Formik state reflects the real value for this field.
    if (!startsWith(newFieldValue, "+")) {
      newFieldValue = `+${newFieldValue}`;
    }

    setFieldValue(name, newFieldValue, true);
    setFieldTouched(name, true);
  };

  const className = classnames("react-phone-input form-field-phone", {
    "form-field-phone--disabled": disabled,
  });

  function getReadOnlyContent() {
    return fieldValue;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} className={className} displayDefaultLabel={displayDefaultLabel}>
      <PhoneInput
        name={name}
        value={fieldValue}
        placeholder={placeholder}
        inputProps={{ className: inputClassNames }}
        onChange={handleChangePhone}
        disabled={disabled}
        enableLongNumbers
        enableTerritories
      />

      <Button onClick={handleClickClear} style={{ marginLeft: 8 }} icon="close" disabled={disabled || !fieldValue}>
        Clear
      </Button>
    </FormFieldFormItem>
  );
}

FormFieldPhone.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldPhone.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldPhone);
