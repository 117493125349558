import React from "react";
import gql from "graphql-tag";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../../constants/routeConstants";
import NoMatchPath from "../../NoMatchPath";
// eslint-disable-next-line import/no-cycle
import MyProfileContainer from "./MyProfileContainer";
// eslint-disable-next-line import/no-cycle
import MyRegistrationsContainerCompletedList from "./MyRegistrationsContainerCompletedList";
// eslint-disable-next-line import/no-cycle
import MyRegistrationsContainerCurrentList from "./MyRegistrationsContainerCurrentList";
// eslint-disable-next-line import/no-cycle
import PersonalDetailCompetitionsContainer from "./PersonalDetailCompetitionsContainer";
import "./MyRegistrationsPage.scss";

export const NAVIGATION_ITEMS = {
  CURRENT_REGISTRATIONS: {
    path: ROUTES.MY_REGISTRATIONS_CURRENT_REGISTRATIONS,
    title: "Current Registrations",
  },
  COMPLETED_REGISTRATIONS: {
    path: ROUTES.MY_REGISTRATIONS_COMPLETED_REGISTRATIONS,
    title: "Completed Registrations",
  },
  COMPETITIONS: {
    path: ROUTES.MY_REGISTRATIONS_COMPETITIONS,
    title: "Competitions",
  },
  PROFILE: {
    path: ROUTES.MY_REGISTRATIONS_COMPLETED_PROFILE,
    title: "Profile",
  },
};

export const GET_PERSON = gql`
  query MyRegistrationsPage_GetPerson($auth0Id: String!) {
    User(where: { auth0Id: { _eq: $auth0Id } }) {
      id
      Person {
        id
        firstName
      }
    }
  }
`;

function MyRegistrationsPage() {


  return (
    <Switch>
      <Route
        exact
        path={NAVIGATION_ITEMS.CURRENT_REGISTRATIONS.path}
        component={() => (
          <MyRegistrationsContainerCurrentList />
        )}
      />
      <Route
        exact
        path={NAVIGATION_ITEMS.COMPLETED_REGISTRATIONS.path}
        component={() => (
          <MyRegistrationsContainerCompletedList />
        )}
      />
      <Route
        exact
        path={NAVIGATION_ITEMS.COMPETITIONS.path}
        component={() => (
          <PersonalDetailCompetitionsContainer />
        )}
      />
      <Route
        exact
        path={NAVIGATION_ITEMS.PROFILE.path}
        component={() => <MyProfileContainer />}
      />
      <Route component={NoMatchPath} />
    </Switch>
  );
}

export default MyRegistrationsPage;
